import axios from "axios";
import {showFailToast, showNotify} from "vant";

const service = axios.create({
    baseURL: 'https://adminpro.xhhx888.cn/app-api',
    // baseURL: 'http://127.0.0.1:8080',
    timeout: 5000
})

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
service.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        Promise.reject(error);
    }
)

service.interceptors.response.use(
    res => {
        console.log(res.data);
        const code = res.data.code || 200;
        const msg = res.data.msg || '系统错误';
        if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
            return res.data
        }
        if (code === 500) {
            showFailToast(msg);
            return Promise.reject(new Error(msg))
        } else if (code === 601) {
            showFailToast(msg);
            return Promise.reject('error')
        } else if (code !== 200) {
            showNotify(msg);
            return Promise.reject('error')
        } else {
            return res.data;
        }
    },
    error => {
        let {message} = error;
        if (message === 'Network Error') {
            message = "网络错误";
        } else if (message.includes('timeout')) {
            message = "接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        showFailToast(message);
        return Promise.reject(error);
    }
)

export default service;
